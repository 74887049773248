.field {
    /*margin-top: 14px;*/
    position: relative;
    margin-bottom: 24px;
    background: $color-white;
    padding: 0 6px 6px 6px;
    border: 1px solid $color-gray-T3;
    box-sizing: border-box;
    box-shadow: 0px 3px 6px rgb(75 81 91 / 15%);
    border-radius: 10px;
    max-width: 400px;
    min-height: 20px;
    min-width: 180px;
    color: $color-gray-T2;
    font-size: 14px;
    line-height: 14px;
    margin-right: 16px;
    /*z-index: 10;*/

    &.is-error {
        border-color: $color-red;
        box-shadow: 0px 0px 16px rgba(51, 51, 51, 0.04);
    }

    &.m--red {
        display: inline-flex;
        border: 1px solid $color-red;
        box-shadow: 0px 0px 16px rgba(51, 51, 51, 0.04);
        border-radius: 8px;
        overflow: hidden;
        margin-bottom: 32px;
    }
    &.m--with-button {
        display: flex;
        overflow: hidden;
        padding-right: 0;
        .field__input {
            top: auto;
            width: 100%;
            input {
                height: 100%;
            }
        }
        .button {
            margin-top: -5px;
            margin-right: -3px;
            margin-bottom: -5px;
            border-radius: 0;
        }
    }
    &-error,
    &__error {
        display: block;
        position: absolute;
        right: 8px;
        top: calc(50% - 14px);
        width: 28px;
        height: 28px;
        background-image: url('./assets/img/alert_password.svg');
        background-repeat: no-repeat;
        background-size: contain;

        &-list {
            display: none;
            position: absolute;
            background-color: $color-gray-T5;
            padding: 8px 16px;
            box-shadow: 0 3.65527px 7.31053px rgba(33, 36, 41, 0.05);
            border-radius: 10px;
            width: 200px;
            right: -8px;
            top: 32px;
            z-index: 2;
        }

        &:hover &-list {
            display: block;
        }

        &.bet {
            right: 52px;
        }
    }

    &.m--checkbox {
        box-shadow: none;
        border: none;
        padding: 0;
        min-width: auto;
        margin-left: 4px;
        input[type=checkbox] {
            position: absolute;
            z-index: -1;
            opacity: 0;
        }
        input[type=checkbox] + label {
            position: relative;
            display: inline-block;
            padding-left: 40px;
            padding-top: 4px;
            cursor: pointer;
            color: $color-black;
            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 24px;
                height: 24px;

                border-radius: 4px;
                border: 1px solid $color-gray-T3;
                box-shadow: 0px 3px 6px rgb(75 81 91 / 15%);
            }
        }
        input[type=checkbox]:checked + label {
            &:before {
                border: 1px solid $color-red;
                background-color: $color-red;
                background-image: url('./assets/img/check.svg');
                background-position: 50% 50%;
                background-size: 13px 10px;
                background-repeat: no-repeat;
            }
        }
    }

    &.m--radio {
        box-shadow: none;
        border: none;
        padding: 0;
        min-width: auto;
        margin-left: 4px;
        input[type=radio] {
            position: absolute;
            z-index: -1;
            opacity: 0;
        }
        input[type=radio] + label {
            position: relative;
            display: inline-block;
            padding-left: 40px;
            padding-top: 4px;
            min-height: 24px;
            cursor: pointer;
            color: $color-black;
            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 24px;
                height: 24px;
                border-radius: 12px;
                border: 1px solid $color-gray-T3;
                box-shadow: 0px 3px 6px rgb(75 81 91 / 15%);
            }
        }
        input[type=radio]:checked + label {
            &:before {
                border: 2px solid $color-red;
            }
            &:after {
                content: '';
                position: absolute;
                top: 2px;
                left: 2px;
                width: 20px;
                height: 20px;
                border-radius: 10px;
                border: 2px solid $color-white;
                background: $color-red;
            }
        }
    }

    &.m--new-bet {
        padding-right: 0;
    }
    &.m--small {
        padding-bottom: 0;
    }

    &.m--half {
        width: 50%;
    }

    &.m--full {
        width: auto;
        max-width: none;
    }

    &.m--large {
        width: 100%;
        min-height: 120px;
        max-width: none;
    }

    &.m--large > &__input {
        height: calc(100% - 20px);
    }

    &.m--large > &__input > textarea {
        height: 100%;
        min-height: 150px;
    }

    &.m--min-width-100 {
        min-width: 100px;
    }

    &.m--min-width-120 {
        min-width: 120px;
    }

    &.m--min-width-140 {
        min-width: 140px;
    }

    &.m--min-width-160 {
        min-width: 140px;
    }

    & > * {
        outline: none;
    }

    &.field--type_select {
        .multiselect {
            top: -2px;
        }
    }

    &-span,
    &__row {
        display: flex;
        justify-content: space-between;
        &.m--grid {
            display: grid;
        }
    }

    &-input,
    &__input {
        position: relative;
        top: -8px;
        .vue-treeselect__menu-container {
            position: relative;
            border: none;
            border-radius: 0;
        }
        .vue-treeselect__menu {
            position: relative;
            top: auto;
            left: auto;
            right: auto;
            border: none;
            width: 100%;
            box-shadow: none !important;
        }
        &.m--bet {
            display: flex;
            height: 20px;
        }
        &#shipmentTime > input {
            font-weight: 600;
            font-size: 14px;
            line-height: 24px;
            color: $color-black;
        }
        &.v-html {
            padding: 8px 16px 16px;
            font-weight: 400;
            font-size: 14px;
            color: $color-black;
            min-height: 120px;
        }
    }

    &-title,
    &__title {
        color: #09101D;
        position: relative;
        left: 5px;
        background-color: white;
        border: white;
        width: fit-content;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        top: -12px;
        padding: 0 5px;
        margin-right: 15px;
    }

    &__value.m--address {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: -21px -7px -7px;
        span {
            padding-left: 14px;
            color: $color-black;
        }
        a {
            position: relative;
            display: block;
            width: 48px;
            overflow: hidden;
            &:after {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                background: $color-gray-T2 url('./assets/img/map.svg') no-repeat center;
            }
        }
    }

    .multiselect,
    .m--multiselect {
        top: -10px;
        min-height: auto;
        &__input {
            line-height: 24px;
        }
        &__single {
            white-space: nowrap;
            overflow: clip;
            text-overflow: ellipsis;
        }
    }
    &__city {
        position: relative;
        top: -10px;
        margin-bottom: -10px;
    }
    @mixin responsive-le-s {
        &.m--half {
            width: auto;
            max-width: none;
        }
        &__row {
            display: block;
            &.m--card {
                box-shadow: 0px 0px 1px rgb(0 0 0 / 24%), 0px 0px 20px rgb(0 0 0 / 12%);
                margin: 0 24px 24px;
                border-radius: 10px;
                padding: 16px;
            }
        }
        &__city {
            .vue-treeselect__menu-container {
                position: relative;
                border: none;
                border-radius: 0;
            }
            .vue-treeselect__menu {
                position: relative;
                top: auto;
                left: auto;
                right: auto;
                border: none;
                width: 100%;
                box-shadow: none !important;
            }
        }
    }
    @mixin responsive-xxs {
        margin-right: 0;
        &__row {
            display: block;
            &.m--card {
                margin: 0 8px 16px;
            }
        }
    }
}
