.reports {
    &__form {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        max-width: 800px;
        justify-content: space-between;
        &-field {
            margin-bottom: 24px;
            margin-right: 0;
            max-width: 300px;
            width: 33%;
        }
        &-submit {
            /*margin-top: 14px;*/
            margin-bottom: 24px;
            .make-report {
                content: url('./assets/img/sliders.svg');
                margin-right: 8px;
            }
        }
    }

    &__items {
        display: flex;
        flex-direction: column;
        box-shadow: 0 16px 24px rgba(0, 0, 0, 0.06), 0 2px 6px rgba(0, 0, 0, 0.04), 0 0 1px rgba(0, 0, 0, 0.04);
        border-radius: 10px;
        max-width: 800px;
        padding: 24px 32px;
        font-size: 14px;
        &-header {
            display: flex;
            justify-content: space-between;
            margin-bottom: 16px;
            color: $color-gray-T2;
        }
    }

    &__report {
        display: flex;
        justify-content: space-between;
        padding: 12px 0;
        border-bottom: 1px solid $color-gray-T4;
        &-field {
            width: 38%;

            &.link {
                width: 20%;
            }
        }

        &-link {
            color: $color-red;
            text-decoration: underline;
            cursor: pointer;
            font-size: 16px;
            &:hover {
                text-decoration: none;
            }
        }
    }
    @mixin responsive-le-s {
        &__form {
            &-field {
                width: 48%;
            }
        }
    }
    @mixin responsive-le-xs {
        &__form {
            &-field {
                width: 100%;
            }
        }
        &__items {
            padding: 16px;
        }
    }
    @mixin responsive-xxs {
        &__form {
            &-field {
                width: 100%;
            }
        }
        &__items {
            font-size: 12px;
        }
        &__report {
            &-field {
                width: 90px;
                &.link {
                    width: auto;
                    text-align: right;
                }
            }
            &-link {
                font-size: 12px;
            }
        }
    }
}