.login {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 16px 8px;
    &__form {
        padding: 48px 40px;
        background: $color-white;
        border: 2px solid $color-gray-T4;
        border-radius: 16px;
        display: flex;
        width: 100%;
        max-width: 404px;
        flex-direction: column;
        margin: auto;

        &-title {
            margin-bottom: 16px;
            color: $color-gray-T2;
            .h2 {
                color: $color-black;
            }
        }

        &-field {
            width: 100%;
            margin: 24px 0 0;
            position: relative;
        }

        &-submit {
            display: block;
            width: 100%;
            margin-top: 40px;
        }
    }

    @media (max-width: 480px) {
        &__form {
            padding: 24px 16px;
        }
    }
}