/*
@keyframes spinner {

    0% {
        transform: rotate(0);
    }
    50% {
        transform: rotate(180deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.spinner {
    width: 140px;
    height: 140px;
    border-radius: 50%;
    background-image: linear-gradient(to bottom, $color-white 0, $color-gray-T2 100%);
    animation-duration: 1s;
    animation-delay: 0;
    animation-timing-function: linear;
    animation-name: spinner;
    animation-iteration-count: infinite;

    &::after {
        content: '';
        position: absolute;
        left: 20%;
        top: 20%;
        width: 60%;
        height: 60%;
        border-radius: 50%;
        background: rgba($color-black, 0.2);
    }
}
*/
@keyframes spin {
    from {
        transform: rotate(0);
    }
    to {
        transform: rotate(359deg);
    }
}

.spinner {
    width: 50px;
    height: 50px;
    border: 3px solid #fb5b53;
    border-top: 3px solid transparent;
    border-radius: 50%;
    animation: spin .5s linear 0s infinite;
}
