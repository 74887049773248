.timer {
    box-shadow: 0 13px 19px rgba(0, 0, 0, 0.07);
    border-radius: 10px;
    padding: 4px 16px;
    display: flex;
    align-items: center;
    max-height: 48px;
    border: 1px solid transparent;
    margin-right: 16px;

    &.has-timer {
        min-width: 215px;
    }

    &.is-hidden {
        display: none;
    }

    &.expires-sometime {
        color: $color-green;
        background-color: $color-green-light;
        border-color: $color-green;
    }
    &.expires-today {
        color: $color-yellow;
        background-color: $color-yellow-light;
        border-color: $color-yellow;
    }

    &.expires-soon, &.expired {
        color: $color-red;
        background-color: $color-red-light;
        border-color: $color-red;
    }

    &__icon {
        min-width: 28px;
        height: 28px;
        /*margin-right: 4px;*/
        background-size: contain;
        background-repeat: no-repeat;
    }

    &.expires-sometime &__icon {
        background-image: url("./assets/img/clock-green.svg");
    }
    &.expires-today &__icon {
        background-image: url("./assets/img/clock-yellow.svg");
    }

    &.expires-soon &__icon, &.expired &__icon {
        background-image: url("./assets/img/clock-red.svg");
    }

    &__status {
        font-weight: 400;
        font-size: 14px;
        max-width: 125px;
        line-height: 15px;
    }

    &__counter {
        font-weight: 600;
        font-size: 26px;
        display: flex;
        align-items: center;
        color: $color-black;
        justify-content: center;
        width: 100%;
        &-text {
            font-weight: 400;
            font-size: 14px;
            padding-right: 8px;
        }
    }

    @mixin responsive-le-m {
        &__counter {
            &-text {
                display: none;
            }
        }
    }

    @mixin responsive-xxs {
        &.has-timer &__status {
            display: none;
        }
    }
}

.tender .timer {
    &__icon {
        margin-right: 12px;
    }
    &__counter {
        width: auto;
    }

    @mixin responsive-le-m {
        &__icon {
            margin-right: 0;
        }
        &__counter {
            width: 100%;
        }
    }
}