.navBar {
    &__header {
        padding: 16px;
        height: 80px;
        position: relative;
        background-color: $color-gray-T4;
        &-logo {
            position: absolute;
            left: 54px;
            top: 13px;
            content: url("./assets/img/img_1.png");
            width: 104px;
            height: 53px;
            z-index: 1;
        }
    }
    &__username {
        position: relative;
        display: none;
        background-color: $color-gray-T4;
        padding: 31px 16px;        
        color: $color-black;
        font-weight: 500;
        font-size: 16px;
        line-height: 115%;
    }
    &__menu {
        max-width: 200px;
        width: 100%;
        position: absolute;
        left: 0;
        top: 80px;
        box-shadow: 0 4px 80px rgba(0, 0, 0, 0.06);
        background: $color-white;
        &-outer {
            position: fixed;
            height: 100vh;
            width: 100%;
            z-index: 2;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background: rgba($color-black, 0.2);
            display: none;
            cursor: pointer;
            &.is-open {
                display: block;
            }
        }

        &-inner {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        &-alert {
            position: absolute;
            display: block;
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            width: 19px;
            top: 24px;
            right: 62px;
        }

        &-item {
            width: 100%;
            text-align: center;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: $color-gray-T1;
            position: relative;
            padding: 97px 0 16px;
            &.is-active, &.is-subactive {
                color: $color-red;
            }

            &:hover {
                color: $color-red;
                background-color: $color-red-light;
            }

            &:after {
                content: '';
                position: absolute;
                width: 70px;
                height: 70px;
                background-position: center;
                background-size: contain;
                background-repeat: no-repeat;
                left: calc(50% - 35px);
                bottom: calc(16px + 19px + 8px);
            }

            &.m--tender {
                &:after {
                    background-image: url('./assets/img/tender.svg');
                }

                &.is-active:after, &.is-subactive:after {
                    background-image: url('./assets/img/tender-active.svg');
                }
            }

            &.m--archive {
                &:after {
                    background-image: url('./assets/img/archive.svg');
                }

                &.is-active:after, &.is-subactive:after {
                    background-image: url('./assets/img/archive-active.svg');
                }
            }

            &.m--news {
                &:after {
                    background-image: url('./assets/img/news.svg');
                }

                &.is-active:after, &.is-subactive:after {
                    background-image: url('./assets/img/news-active.svg');
                }
            }

            &.m--vendors {
                &:after {
                    background-image: url('./assets/img/vendors.svg');
                }

                &.is-active:after, &.is-subactive:after {
                    background-image: url('./assets/img/vendors-active.svg');
                }
            }

            &.m--reports {
                &:after {
                    background-image: url('./assets/img/report.svg');
                }

                &.is-active:after, &.is-subactive:after {
                    background-image: url('./assets/img/report-active.svg');
                }
            }

            &.m--notifications {
                &:after {
                    background-image: url('./assets/img/notification.svg');
                }
                &.alarm:after {
                    background-image: url('./assets/img/notifications-alarm.svg');
                }
                &.is-active:after, &.is-subactive:after {
                    background-image: url('./assets/img/notification-active.svg');
                }
            }

            &.m--chat {
                &:after {
                    background-image: url('./assets/img/chat.svg');
                    left: calc(50% - 30px);
                }

                &.has-msg:after {
                    background-image: url('./assets/img/chat-msg.svg');
                }

                &.alarm:after {
                    background-image: url('./assets/img/chat-alarm.svg');
                }

                &.is-active:after, &.is-subactive:after {
                    background-image: url('./assets/img/chat-active.svg');
                }

                &.has-msg.is-active:after, &.has-msg.is-subactive:after {
                    background-image: url('./assets/img/chat-msg-active.svg');
                }
            }

            &.m--profile {
                &:after {
                    background-image: url('./assets/img/profile.svg');
                }

                &.is-active:after, &.is-subactive:after {
                    background-image: url('./assets/img/profile-active.svg');
                }
            }
        }
        &-logout {
            margin-top: 20px;
            margin-bottom: 20px;
            height: 38px;
            padding: 9px 16px;
            width: 170px;
            text-align: center;
            display: none;
        }
        &-close {
            position: absolute;
            display: none;
            top: 0;
            right: 0;
            bottom: 0;
            width: 56px;
            @mixin gradient-lighten right, $color-gray-T1, 20%;
            cursor: pointer;
            &:after {
                content: url("./assets/img/close.svg");
                position: absolute;
                display: block;
                top: 32px;
                left: 50%;
                margin-left: -7px;
                width: 14px;
                height: 14px;
            }
        }
    }
    @mixin responsive-le-l {
        &__header {
            &-logo {
                left: 22px;
            }
        }
        &__menu {
            max-width: 150px;
            &-alert {
                right: 37px;
            }
        }
    }
    @mixin responsive-le-m {
        &__header {
            &-container {
                left: 190px;
            }
            &-logo {
                left: 22px;
                top: 14px;
            }
        }
        &__username {
            display: block;
        }
        &__menu {
            top: 0;
            right: 0;
            left: auto;
            max-width: 360px;
            max-height: 100vh;
            overflow: hidden;
            transition: transform .3s ease-in-out;
            transform: translateX(360px);
            z-index: 3;
            &.is-open {
                display: block;
                transform: translateX(0);
                max-height: none;
            }
            &-inner {
                padding: 0;
                margin-right: 56px;
            }
            &-alert {
                position: relative;
                display: inline-block;
                border-radius: 50%;
                border: 2px solid $color-red;
                min-width: 23px;
                margin-left: 8px;
                text-align: center;
                right: 0;
                top: 0;
            }
            &-item {
                padding: 16px 16px 16px 80px;
                text-align: left;
                margin: 0;
                line-height: 24px;
                &:after {
                    left: 16px;
                    top: 50%;
                    margin-top: -18px;
                    width: 36px;
                    height: 36px;
                }
                &:hover,
                &.is-active {
                    background-color: $color-red-light;
                }
                &.m--chat {
                    &:after {
                        left: 16px;
                    }

                    &.has-msg:after {
                        background-image: url('./assets/img/chat-alarm.svg');
                    }
                    &.has-msg.is-active:after, &.has-msg.is-subactive:after {
                        background-image: url('./assets/img/chat-active.svg');
                    }
                }

            }
            &-logout {
                display: block;
            }
            &-close {
                display: block;
            }
        }
    }
    @mixin responsive-le-s {
        &__header {
            padding: 12px;
            height: 72px;
            &-container {
                left: 164px;
            }
            &-logo {
                left: 16px;
                width: 78px;
                height: 40px;
            }
        }
        &__username {
            padding-top: 27px;
            padding-bottom: 27px;
        }
        &__menu {
            &-close {
                &:after {
                    top: 28px;
                }
            }
        }
    }
    @mixin responsive-le-xs {
        &__header {
            &-logo {
                width: 70px;
                height: 36px;
            }
            &-container {
                left: 120px;
            }
        }        
    }
    @mixin responsive-xxs {
        &__header {
            &-container {
                left: 105px;
            }
        }        
    }
}