.news {

    &__items {
        display: flex;
        flex-direction: column;
    }

    &__item {
        width: 100%;
        font-size: 14px;
        line-height: 22px;
        color: $color-black;
        padding: 16px;
        border: 1px solid $color-gray-T4;
        box-shadow: 0px 3px 6px rgb(75 81 91 / 15%);
        border-radius: 10px;
        margin-bottom: 40px;
        &-header {
            display: flex;
            margin-bottom: 10px;
            justify-content: space-between;
            align-items: start;
        }
        &-title {
            font-size: 24px;
            line-height: 34px;
            color: $color-black;
        }
        &-info {
            display: flex;
            flex-direction: column;
            color: $color-gray-T3;
            text-align: right;
            margin-left: auto;
            margin-left: 16px;
            span {
                display: block;
                &.m--nowrap {
                    white-space: nowrap;
                }
            }
            strong {
                font-weight: bold;
                padding-right: 4px;
            }
        }
        &-text {
            margin-bottom: 10px;
        }
        &-more {
            color: $color-red;
            text-decoration: underline;
            &:hover {
                text-decoration: none;
            }
        }
    }
    @mixin responsive-le-xs {
        &__item {
            &-header {
                flex-direction: column;
            }
            &-title {
                margin-bottom: 10px;
            }
            &-info {
                flex-direction: row-reverse;
                margin-left: 0;
                justify-content: space-between;
                width: 100%;
            }
        }
    }
}