.tabs {
    display: flex;
    justify-content: flex-start;
    margin: 0 0 10px;
    align-items: end;
    flex-wrap: wrap;
    &__item {
        display: block;
        margin-bottom: 15px;
        a {
            display: block;
            padding: 16px;
            color: $color-gray-T3;
            border-bottom: 2px solid $color-gray-T3;
            font-weight: 600;
            border-radius: 4px 4px 0 0;
            &.is-active {
                color: $color-red;
                border-bottom: 2px solid $color-red;
            }

            &:hover {
                background-color: $color-red-light;
                color: $color-red;
                border-color: $color-red;
            }
        }
    }
}