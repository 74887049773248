/* Переопределение библиотечных стилей в мультиселекте выбора городов
Подключать ПОСЛЕ основных стилей мультиселекта, в противном случае везде проставить */

/* Граница селекта */
.vue-treeselect__control {
    border: none;
}
/* Расположение выпадашки */
.vue-treeselect__menu {
    width: calc(100% + 14px);
    position: relative;
    left: -7px;
    max-height: 500px;
}
/* цвет стрелки узла */
.vue-treeselect__option-arrow {
    color: #E31D2C;
    width: 13px;
    height: 13px;
}
/* нестандартный разворот стрелки узла при раскрытии */
.vue-treeselect__option-arrow--rotated {
    -ms-transform: rotate(-45deg);
    transform: rotateZ(-45deg);
}
/* размер чекбокса */
.vue-treeselect__checkbox {
    width: 20px;
    height: 20px;
    border-radius: 3px;
}
/* размер галочки */
.vue-treeselect__check-mark {
    width: 17px;
    height: 17px;
    background-position: center;
    transform: unset;
    left: 0;
    top: 0;
}
/* размер и положение галочки "частично выбран" */
.vue-treeselect__minus-mark {
    width: 17px;
    height: 17px;
    background-position: center;
    left: 0;
    top: 0;
}
/* цвет чекбокса "частично выбран" */
.vue-treeselect__checkbox--indeterminate {
    border-color: #E31D2C;
    background: #E31D2C;
}
/* цвет чекбокса "выбран" */
.vue-treeselect__checkbox--checked {
    border-color: #E31D2C;
    background: #E31D2C;
}
/* цвет чекбокса "не выбран" */
.vue-treeselect__checkbox--unchecked {
    border-color: #E31D2C;
    background: white;
}
/* лейбл выбранного элемента в самом селекте */
.vue-treeselect__multi-value-item {
    cursor: pointer;
    background: rgba(227, 29, 44, 0.4);
    border-radius: 3px;
    color: unset;
}
/* крестик на лейбле */
.vue-treeselect__value-remove {
    color: unset;
}
/* крестик на лейбле при наведении */
.vue-treeselect__multi-value-item:hover .vue-treeselect__value-remove {
    color: white;
}