.tender {
    &__header {
        margin-bottom: 50px;

        &-block {
            display: flex;
            /*align-items: center;*/
            align-items: flex-start;
            margin-bottom: 24px;
        }

        &-code {
            color: $color-gray-T2;
            font-weight: 500;
            font-size: 20px;
            line-height: 28px;
            margin-right: 8px;
        }

        &-name {
            margin-right: 24px;
        }
        &-message {
            margin-left: auto;
            font-weight: 600;
            box-shadow: 0px 0px 2px rgba(0 0 0 / 24%), 0px 0px 8px rgba(0 0 0 / 12%);
            &-icon {
                background: $color-gray-T2 url("./assets/img/write.svg") no-repeat center;
                border-radius: 10px;
                width: 48px;
                height: 48px;
                position: relative;
                left: -16px;
            }
            &-label {
                padding-left: 20px;
                padding-right: 20px;
            }
            &:active:not(:disabled) &-icon {
                top: -2px;
            }
        }

        &-labels {
            margin-right: auto;
            display: flex;
            align-items: center;
        }

        &-status {
            background: $color-gray-T5;
            border: 1px solid $color-gray-T3;
            border-radius: 10px;
            color: $color-black;
            height: 48px;
            padding-left: 16px;
            padding-right: 16px;
            display: flex;
            align-items: center;
            margin-right: 16px;
            line-height: 15px;

            &.m--confirmed {
                background: $color-green-light;
                border: 1px solid $color-green;
            }

            &.m--waiting {
                background: $color-yellow-light;
                border: 1px solid $color-yellow;
            }

            &.m--docs {
                max-width: 124px;
            }

            &.m--no-docs {
                background: $color-red-light;
                border: 1px solid $color-red;
            }

            &.m--yes-docs {
                background: $color-green-light;
                border: 1px solid $color-green;
            }

        }

        &-auction {
            display: flex;
        }

        &-dates {
            font-size: 14px;
            line-height: 22px;
            margin-left: 40px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            &-title {
                margin-right: 8px;
                font-weight: 700;
            }
        }
    }
    &__main {
        box-shadow: 0px 0px 1px rgb(0 0 0 / 24%), 0px 0px 20px rgb(0 0 0 / 12%);
        border-radius: 10px;
        margin-bottom: 40px;
        width: 100%;
        overflow: hidden;
        &-body {
            display: grid;
            grid-template-columns: repeat(6, 1fr);
            text-align: center;
            align-content: center;
        }

        &-field {
            height: 48px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 8px 16px;
        }

        &-title {
            background-color: $color-gray-T5;
            border-bottom: 1px solid $color-shadow;
            box-shadow: inset 0 -1px 0 $color-shadow;
        }
    }
    &__info {
        display: flex;
        justify-content: space-between;
        &-left {
            width: 39%;
            /*margin-top: -14px;*/
            margin-bottom: 32px;
        }
        &-right {
            width: 59%;
            margin-bottom: 32px;
        }
        &-bets {
            display: flex;
            justify-content: space-between;
            margin-bottom: 14px;
        }
        &-bet {
            max-width: 180px;
            min-width: 110px;
            height: 70px;
            margin-bottom: 20px;
            margin-right: 14px;
            padding: 16px 24px;
            box-shadow: 0px 0px 1px rgb(0 0 0 / 24%), 0px 0px 20px rgb(0 0 0 / 12%);
            border-radius: 10px;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            background: $color-white;
            &:last-child {
                margin-right: 0;
            }

            &.m--best {
                background: $color-green-light;
            }

            .m--sum {
                font-weight: 600;
                font-size: 18px;
                padding-bottom: 5px;
                letter-spacing: 0.1em;
                color: $color-black;
            }
            .m--text {
                font-size: 14px;
                line-height: 18px;
                color: $color-gray-T2;
                white-space: nowrap;
            }
        }
    }

    &__bets {
        box-shadow: 0 0 1px rgb(0 0 0 / 24%), 0 0 20px rgb(0 0 0 / 12%);
        border-radius: 10px;
        padding: 16px 24px;
        &-table {
            &-header {
                font-size: 14px;
                color: $color-gray-T2;
                display: grid;
                grid-template-columns: 2fr 2fr 1fr;
                margin-bottom: 10px;
                .m--right {
                    text-align: right;
                }
            }
            &-row {
                border-bottom: 1px solid $color-gray-T4;
                display: grid;
                grid-template-columns: 2fr 2fr 1fr;
                padding-top: 16px;
                padding-bottom: 16px;
                .m--right {
                    text-align: right;
                }
                &:first-child {
                    padding-top: 0;
                }
                &:last-child {
                    border-bottom: 0;
                }
            }
            &-cell {
                &.m--supplier {
                    color: $color-lightblack;
                }

                &.m--bet-time {
                    color: $color-gray;
                }

                &.m--bet-sum {
                    color: $color-lightblack;
                    text-align: right;
                }
            }

        }
    }
    &__footer {
        &-status {
            margin-bottom: 45px;
            &-item {
                display: flex;
                align-items: center;
                color: $color-yellow;
                font-weight: 600;
                .m--icon {
                    cursor: default;
                }
                .m--wait {
                    margin-right: 16px;
                    background: url("./assets/img/sand-clock.svg") no-repeat center;
                }
            }
        }
        &-card {
            box-shadow: 0px 0px 1px rgb(0 0 0 / 24%), 0px 0px 20px rgb(0 0 0 / 12%);
            border-radius: 10px;
            margin-bottom: 40px;
            width: 100%;
            overflow: hidden;
            padding: 20px 25px;
            &-row {
                display: flex;
            }
            &-cell {
                width: 33%;
                margin-bottom: 30px;
                &-title {
                    font-weight: 600;
                    font-size: 16px;
                    margin-bottom: 16px;
                    position: relative;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                }
                &-info {
                    font-size: 14px;
                    b, strong {
                        font-weight: 600;
                    }
                }
                &-edit, &-add {
                    width: 24px;
                    height: 24px;
                    background-repeat: no-repeat;
                    background-size: contain;
                    background-position: center;
                    display: inline-block;
                    margin-left: 16px;
                    top: -4px;
                    cursor: pointer;
                }
                &-edit {
                    background-image: url("./assets/img/edit.svg");
                }
                &-add {
                    margin-left: 8px;
                    background-image: svg-load('assets/img/close.svg', fill=$color-red);
                    background-size: 18px;
                    transform: rotate(45deg);
                }
            }
            &-buttons {
                display: flex;
                justify-content: flex-end;
            }
        }
    }

    @mixin responsive-le-m {
        &__header {

            &-block {
                flex-wrap: wrap;
            }
            &-labels {
                margin-bottom: 16px;
            }
        }
    }
    @mixin responsive-le-s {
        &__header {
            margin-bottom: 16px;
            &-auction {
                flex-wrap: wrap;
            }
            &-timer {
                margin: 0 16px 16px 0;
            }
            &-dates {
                margin: 0 0 16px;
            }
            &-status {
                font-size: 13px;
                line-height: 15px;
                margin-right: 16px;
                &:last-child {
                    margin-right: 0;
                }
            }
        }
        &__main {
            &-body {
                display: grid;
                grid-template-columns: repeat(1, 1fr);
                text-align: center;
                align-content: center;
            }
            &-block {
                display: flex;
            }
            &-field {
                width: 50%;
                border-bottom: 1px solid $color-shadow;
            }
            &-title {
                justify-content: flex-start;
            }
        }
        &__info {
            flex-direction: column-reverse;
            &-left,
            &-right {
                width: 100%;
                margin-bottom: 16px;
            }
            &-bets {
                flex-wrap: wrap;
            }
            &-bet {
                min-width: 160px;
            }
        }
        &__footer {
            &-card {
                &-row {
                    flex-wrap: wrap;
                }
                &-cell {
                    width: 49%;
                }
            }
        }
    }
    @mixin responsive-le-xs {

        &__bets {
            padding: 16px 8px;
            margin-bottom: 16px;
            &-table {
                &-header, &-row {
                    column-gap: 8px;
                }
                &-row {
                    padding: 8px 0;
                }
            }
        }

        &__header {
            &-name {
                margin-right: 64px;
                word-break: break-all;
            }
            &-message {
                padding: 0;
                position: absolute;
                right: 0;
                &:active:not(:disabled) {
                    padding: 0;
                }
                &-label {
                    display: none;
                }
                &-icon {
                    left: 0;
                }
            }
            &-status {
                padding: 12px 8px;
                height: auto;
                &.m--docs {
                    padding: 8px;
                }
            }
        }
        &__footer {
            &-card {
                &-cell {
                    width: 100%;
                }
                &-buttons {
                    justify-content: center;
                }
            }
        }
    }
}
