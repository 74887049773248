.filter {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &.m--collection {
        border-bottom: none;
        padding-bottom: 0;
        justify-content: flex-start;
        flex-direction: row;
        flex-wrap: wrap;
        margin: 0 -16px 15px;
    }
    &__show {
        display: none;
        font-size: 16px;
        font-weight: 600;
        color: $color-red;
        text-decoration: underline;
        margin-bottom: 24px;
        margin-left: auto;
        cursor: pointer;
        &:hover {
            text-decoration: none;
        }
    }
    &__title {
        display: none;
    }
    &__close {
        position: absolute;
        display: none;
        width: 24px;
        height: 24px;
        top: 32px;
        right: 24px;
        color: $color-black;
        cursor: pointer;
        content: url("./assets/img/close.svg");
    }
    &__inner {
        position: relative;
        display: flex;
        justify-content: space-between;
        /*margin-top: 20px;*/
        /*padding-bottom: 40px;*/
        margin-bottom: 24px;
        background: $color-white;
        border-bottom: 1px solid $color-gray-T4;
    }
    &__block {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
    }
    &__field {
        width: 240px;
        height: 48px;
        color: unset;
        min-width: 240px;
        &.m--height-auto {
            height: auto;
            padding-bottom: 0;
        }
    }
    &__buttons {
        display: flex;
        justify-content: flex-end;
        align-content: flex-start;
        flex-wrap: nowrap;
    }
    &__button {
        height: 48px;
        display: flex;
        justify-content: space-between;
        /*margin-top: 14px;*/
        margin-bottom: 24px;
        margin-left: 16px;
        &.m--add {
            width: 170px;
            background: transparent;
        }
        &.m--add:before {
            content: url('./assets/img/sliders.svg');
        }
        &.m--search {
            width: 100px;
        }
        &.m--search:before {
            content: url('./assets/img/icn-search.svg');
        }
    }

    &__item {
        display: flex;
        padding: 10px 16px;
        margin: 0 16px 25px;
        color: $color-gray-T1;
        border-bottom: 2px solid $color-gray-T4;
        &.is-active {
            color: $color-red;
            border-bottom: 2px solid $color-red;
        }
        &-label {
            cursor: pointer;
        }
        &-delete {
            background-image: svg-load('./assets/img/close.svg', fill=$color-gray-T3);
            background-repeat: no-repeat;
            background-position: center;
            background-size: 10px 10px;
            display: inline-block;
            min-width: 10px;
            min-height: 10px;
            cursor: pointer;
            margin-left: 8px;
            
            &:hover {
                background-image: svg-load('./assets/img/close.svg', fill=$color-red);
            }
        }

        &.is-active &-delete {
            display: none;
        }
    }

    @mixin responsive-le-l {
        &__buttons {
            flex-wrap: wrap;
        }
        &__field {
            width: calc(33% - 16px);
        }
    }
    @mixin responsive-le-m {
        &__field {
            width: calc(50% - 16px);
        }
    }
    @mixin responsive-le-s {
        &.is-open:before {
            content: '';
            position: fixed;
            box-sizing: border-box;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.2);
            opacity: 1;
            z-index: 998;
        }
        &__title {
            display: block;
        }
        &__close {
            display: block;
        }
        &__show {
            display: block;
        }
        &__inner {
            display: none;
            flex-direction: column;
            padding: 24px;
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            /*bottom: 0;*/
            max-height: 100vh;
            /*overflow: auto;*/
            margin-bottom: 0;
            z-index: 999;
        }
        &.is-open &__inner {
            display: flex;
            background: $color-white;

        }
        &__block {
            justify-content: space-between;
            padding: 16px 16px 0 0;
            overflow: auto;
            margin-right: -16px;
        }
        &__buttons {
            padding-top: 16px;
            justify-content: space-between;
            margin-bottom: -24px;
        }
        &__button {
            margin-left: 0;
        }
        &__field {
            width: 48%;
            margin-right: 0;
        }

        &__item {
            padding: 8px 12px;
            margin: 0 12px 12px;
        }
    }
}