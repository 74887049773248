.multiselect {
    &__select {
        height: 100%;
        padding-top: 0;
        &:before {
            position: relative;
            right: 0;
            top: 5px;
            color: unset;
            margin-top: 4px;
            border-color: unset;
            border-style: unset;
            border-width: unset;
            content: url('assets/img/dropdown.svg');
        }
    }
    &__tags {
        border: none;
        min-height: unset;
        height: 35px;
        padding-top: 5px;
    }

    &__option--highlight {
        background: rgba(227, 29, 44, 0.5);
        outline: none;
        color: $color-black;
        border-radius: 10px;
    }
    &__option--selected {
        background: $color-gray-T5;
        color: $color-lightblack;
        font-weight: 600;
        border-radius: 10px;
    }
    &__content-wrapper {
        border-radius: 10px;
        overflow: hidden;
        width: calc(100% + 12px);
        left: -6px;
        border: 1px solid #$color-gray-T3;
        box-sizing: border-box;
        max-height: unset;
    }
    & * {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        letter-spacing: 0.44px;
        color: $color-black;
        overflow-y: auto;
    }
    &__content {
        max-width: 100%;
    }
    &__option {
        white-space: normal;
    }
    &.multiselect--active .multiselect__select {
        transform: unset;
    }
    &.multiselect--active .multiselect__select:before {
        position: relative;
        right: 0;
        top: 5px;
        color: unset;
        margin-top: 4px;
        border-color: unset;
        border-style: unset;
        border-width: unset;
        content: url('assets/img/dropup.svg');
    }
}