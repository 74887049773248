.vendor {
    &__header {
        margin-bottom: 50px;
    }
    &__card {
        max-width: 900px;
        padding: 30px 40px;
        background: $color-white;
        box-shadow: 0px 16px 24px rgba(0 0 0 / 6%), 0px 2px 6px rgba(0 0 0 / 4%), 0px 0px 1px rgba(0 0 0 / 4%);
        border-radius: 10px;
        margin: 30px 0;
        overflow: hidden;
        &-title {
            margin-bottom: 40px;
        }
        &-form {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            margin-right: -16px;
            &-buttons {
                display: flex;
                justify-content: flex-start;
            }
            &-button {
                margin-right: 24px;
            }
        }

        &:first-child {
            margin-top: 0;
        }
    }
    &__buttons {
        margin-bottom: 40px;
    }

    &__docs {
        &-table {
            &-header,
            &-row {
                grid-template-columns: 90px 120px 100px auto 125px 80px;
            }
        }
    }

    &__drivers {
        &-table {
            &-header,
            &-row {
                grid-template-columns: 100px auto 120px 140px 60px 90px 120px 80px;
            }
        }
    }

    &__cars {
        &-table {
            &-header,
            &-row {
                grid-template-columns: 100px 100px 100px 110px 80px 70px auto 80px;
            }
        }
    }

    @mixin responsive-le-l {
    }
    @mixin responsive-le-m {
        &__docs {
            &-table {
                &-header,
                &-row {
                    grid-template-columns: 90px 120px 100px auto 125px 40px;
                }
            }
        }
        &__drivers {
            &-table {
                &-header,
                &-row {
                    grid-template-columns: 100px auto 100px 150px 60px 90px 100px 40px;
                }
            }
        }
        &__cars {
            &-table {
                &-header,
                &-row {
                    grid-template-columns: 100px 100px 100px 110px 80px 70px auto 40px;
                }
            }
        }
    }
    @mixin responsive-le-s {
        &__header {
            margin-bottom: 30px;
        }
        &__card {
            &-title {
                margin-bottom: 20px;
            }
            &-form {
                grid-template-columns: repeat(1, 1fr);
            }
        }
        &__docs {
            &-table {
                &-row {
                    font-size: 14px;
                    grid-template-columns: repeat(1, 1fr);
                    margin-bottom: 24px;
                    /*box-shadow: 0 16px 24px rgb(0 0 0 / 6%), 0 2px 6px rgb(0 0 0 / 4%), 0 0 1px rgb(0 0 0 / 4%);*/
                    box-shadow: 0px 0px 1px rgba(0 0 0 / 24%), 0px 0px 20px rgba(0 0 0 / 12%);
                    border-radius: 10px;
                    overflow: hidden;
                    background: $color-white;
                }
            }
        }
        &__drivers {
            &-table {
                &-row {
                    font-size: 14px;
                    grid-template-columns: repeat(1, 1fr);
                    margin-bottom: 24px;
                    /*box-shadow: 0 16px 24px rgb(0 0 0 / 6%), 0 2px 6px rgb(0 0 0 / 4%), 0 0 1px rgb(0 0 0 / 4%);*/
                    box-shadow: 0px 0px 1px rgba(0 0 0 / 24%), 0px 0px 20px rgba(0 0 0 / 12%);
                    border-radius: 10px;
                    overflow: hidden;
                    background: $color-white;
                }
            }
        }
        &__cars {
            &-table {
                &-row {
                    font-size: 14px;
                    grid-template-columns: repeat(1, 1fr);
                    margin-bottom: 24px;
                    /*box-shadow: 0 16px 24px rgb(0 0 0 / 6%), 0 2px 6px rgb(0 0 0 / 4%), 0 0 1px rgb(0 0 0 / 4%);*/
                    box-shadow: 0px 0px 1px rgba(0 0 0 / 24%), 0px 0px 20px rgba(0 0 0 / 12%);
                    border-radius: 10px;
                    overflow: hidden;
                    background: $color-white;
                    strong {
                        font-size: 16px;
                        text-transform: uppercase;
                    }
                }
            }
        }
    }
    @mixin responsive-le-xs {
        &__header {
            margin-bottom: 20px;
        }
        &__card {
            &-title {
                margin-bottom: 10px;
            }
            padding: 20px;
        }
    }
}
