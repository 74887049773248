.vm--modal {
    background: $color-white;
    box-shadow: 0px 0px 8px rgba(0 0 0 / 24%), 0px 0px 46px rgba(0 0 0 / 12%);
    border-radius: 10px;
}
.modal {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    padding: 32px 56px;

    &__header {
        margin-bottom: 10px;
    }
    &__body {
        padding: 16px 0;
        margin-right: -16px;
    }
    &__form {
        margin-right: -16px; 
    }
    &__footer {
        padding-top: 20px;
        &.m--flex {
            display: flex;
            justify-content: space-between;
        }
    }
    @mixin responsive-le-m {
        padding: 24px 32px;
    }
    @mixin responsive-le-s {
        padding: 16px 24px;
        &__body {
            margin-right: 0;
            overflow: auto;
        }
    }
    @mixin responsive-le-xs {
        padding: 16px 8px;
        &__footer {
            &.m--flex {
                display: flex;
                justify-content: space-around;
            }
        }
    }

    @media (max-height: $document-width-s), (orientation: landscape) {
        &__body {
            overflow: auto;
        }
    }
}