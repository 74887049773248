.vendors {
    padding: 50px 0 0;
    &__buttons {
        margin-bottom: 40px;
    }
    &__table {
        &-header,
        &-row {
            grid-template-columns: 11% 20% auto 7% 7% 7% 7% 7% 90px 80px;
        }
    }

    @mixin responsive-le-l {
        &__table {
            &-header,
            &-row {
                grid-template-columns: 11% 18% auto 7% 7% 8% 7% 7% 90px 40px;
            }
        }
    }
    @mixin responsive-le-m {
        &__table {
            &-header,
            &-row {
                grid-template-columns: 11% 18% auto 8% 8% 8% 8% 8% 90px 40px;
                font-size: 13px;
            }
        }
    }
    @mixin responsive-le-s {
        &__table {
            &-row {
                font-size: 14px;
                grid-template-columns: repeat(1, 1fr);
                margin-bottom: 24px;
                /*box-shadow: 0 16px 24px rgb(0 0 0 / 6%), 0 2px 6px rgb(0 0 0 / 4%), 0 0 1px rgb(0 0 0 / 4%);*/
                box-shadow: 0px 0px 1px rgba(0 0 0 / 24%), 0px 0px 20px rgba(0 0 0 / 12%);
                border-radius: 10px;
                overflow: hidden;
                background: $color-white;
            }
        }
    }
    @mixin responsive-le-xs {
    }
}
