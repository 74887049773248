*,
*::before,
*::after {
    box-sizing: border-box;
}

html {
    min-width: 100%;
    height: 100%;
    height: -webkit-fill-available;
    overflow-x: auto;
}

body {
    @mixin base;

    position: relative;
    min-width: 320px;
    height: 100%;
    min-height: 100vh;
    min-height: -webkit-fill-available;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
}

.container {
    max-width: 1440px;
    /*width: calc(100% - 280px);*/
    margin: auto;
    position: absolute;
    left: 240px;
    right: 40px;
    @mixin responsive-le-l {
        left: 190px;
    }
    @mixin responsive-le-m {
        left: 16px;
        right: 16px;
    }
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.h1 {
    @mixin h1;
}

.h2 {
    @mixin h2;
}

.h3 {
    @mixin h3;
}

.h4 {
    @mixin h4;
}

.p {
    @mixin p;
}

.ul {
    @mixin ul;
}

.ol {
    @mixin ol;
}

.hr {
    @mixin hr;
}

.a, a {
    @mixin a;
    @mixin a--color $color-base;
}

.table {
    @mixin table;
}

.base {
    @mixin base;
}

.format {
    @mixin format;
}

.text {
    @mixin text;
}

.input, input {
    @mixin input;
}

.select, select {
    @mixin select;
}

.textarea, textarea {
    @mixin textarea;
}

.checkbox {
    @mixin checkbox;
}

.radiobox {
    @mixin radiobox;
}

.red-text {
    color: $color-red;
}

.green-text {
    color: $color-green;
}

.gray-text {
    color: $color-gray-T2;
}

.button {
    @mixin button;
    @mixin button--color $color-white, $color-black;

    &-gray {
        @mixin button--color $color-gray-T1, $color-white;
        box-shadow: none;

        &:hover {
            @mixin button--color $color-red, $color-white;
        }
    }

    &-red {
        @mixin button--color $color-red, $color-white;
        box-shadow: none;
        &:hover {
            @mixin button--color $color-red, $color-white;
        }
    }

    &-green {
        box-shadow: none;
        @mixin button--color $color-green, $color-white;
        &:hover {
            @mixin button--color $color-green, $color-white;
        }
    }
    &-transparent {
        background: transparent;
        &:hover {
            background: transparent;
        }
    }
    &.m--close::before {
        content: url("./assets/img/close.svg");
        height: 14px;
        width: 14px;
        margin-right: 13px;
    }
    &.m--check::before {
        content: url("./assets/img/check.svg");
        height: 20px;
        width: 20px;
        margin-right: 13px;
    }
    &-icon {
        width: 48px;
        min-width: 48px;
        height: 48px;
        border-radius: 10px;
        cursor: pointer;
        &.m--check {
            background: $color-green url("./assets/img/check.svg") no-repeat center;
            position: relative;
            top: -13px;
            left: 2px;
            &:before {
                display: none;
            }
            &:hover {
                background: $color-green url("./assets/img/check.svg") no-repeat center;
            }
        }
        &.m--search {
            background: $color-red url("./assets/img/magnifying-glass.svg") no-repeat center;
            &:hover {
                background: $color-green url("./assets/img/magnifying-glass.svg") no-repeat center;
            }
        }

    }
}

.m--icon {
    min-width: 24px;
    height: 24px;
    cursor: pointer;
}
.m--gear {
    background-image: url('./assets/img/gear.svg');
}
.m--lock-open {
    background: url('./assets/img/lock-open.svg') no-repeat center;
}
.m--lock-closed {
    background: url('./assets/img/lock-closed.svg') no-repeat center;
}
.m--plus {
    font-size: 26px;
    line-height: 26px;
    margin-right: 8px;
}

