.tender-item {
    box-shadow: 0px 0px 1px rgb(0 0 0 / 24%), 0px 0px 20px rgb(0 0 0 / 12%);
    border-radius: 10px;
    margin-bottom: 16px;
    cursor: pointer;
    width: 100%;
    overflow: hidden;

    &__header {
        position: relative;
        border-bottom: 3px solid $color-gray-T3;
        display: flex;
        align-items: center;
        padding: 8px 16px;
        justify-content: space-between;

        &-block {
            display: flex;
            align-items: center;
            &.m--wrap {
                flex-wrap: wrap;
            }
        }

        &-code {
            color: $color-gray-T2;
            font-weight: 500;
            font-size: 18px;
            line-height: 22px;
            margin-right: 8px;
        }

        &-name {
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 22px;
            color: $color-black;
            margin-right: 24px;
        }

        &-status {
            background: $color-gray-T5;
            border: 1px solid $color-gray-T3;
            box-sizing: border-box;
            border-radius: 10px;
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            letter-spacing: 0.2px;
            color: $color-black;
            padding: 6px 12px;
            display: flex;
            align-items: center;
            margin: 4px 16px 4px 0;
            line-height: 13px;

            &.is-played {
                background-color: $color-yellow-light;
                border-color: $color-yellow;
                width: min-content;
            }

            &.waiting {
                background-color: $color-yellow-light;
                border-color: $color-yellow;
            }

            &.confirmed {
                background: $color-green-light;
                border-color: $color-green;
            }

            &.docs-status {
                max-width: 130px;

                &.has-docs {
                    background: $color-green-light;
                    border-color: $color-green;
                }

                &.no-docs {
                    background-color: $color-red-light;
                    border-color: $color-red;
                }
            }
            &.end-point {
                display: none;
            }

            &:last-child {
                margin-right: 0;
            }
        }

        &-winner {
            background: url('./assets/img/has_winner.svg') no-repeat center;
            min-width: 24px;
            height: 24px;
            margin-right: 8px;
        }

        &-auction {
            display: flex;
            flex-direction: column;
            font-weight: 700;
            font-size: 14px;
            line-height: 22px;
            color: $color-black;
            margin-left: auto;
            text-align: right;
            /*margin-left: 24px;*/

            &-title {
                white-space: nowrap;
            }

            &-time {
                margin-left: 8px;
                font-weight: 400;
                white-space: nowrap;
            }

            .red-text {
                color: $color-red;
            }

            .green-text {
                color: $color-green;
                margin-left: 0;
            }
        }
        &-slide {
            position: absolute;
            display: none;
            top: calc(100% - 36px - 12px);
            right: 10px;
            width: 36px;
            height: 36px;
            background-color: $color-white;
            border-radius: 50%;
            box-shadow: 0px 2px 4px rgba(38 50 56 / 8%), 0px 4px 6px rgba(38 50 56 / 8%);
            cursor: pointer;
            &:after {
                content: '';
                position: absolute;
                display: block;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                background: url('./assets/img/arrow.svg') no-repeat center;
            }
        }
    }

    &__form {
        display: grid;
        grid-template-columns: repeat(8, 1fr);
        text-align: center;
        align-content: center;
        &-title {
            background-color: $color-gray-T5;
            border-bottom: 1px solid $color-shadow;
            box-shadow: inset 0 -1px 0 $color-shadow;
            font-weight: 600;
        }

        &-field {
            height: 36px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 4px 8px;
            font-size: 13px;
            line-height: 13px;

            &.is-winner {
                &:before {
                    content: '';
                    display: inline-block;
                    background: url('./assets/img/has_winner.svg') no-repeat center;
                    min-width: 24px;
                    height: 24px;
                    margin-right: 8px;
                }
            }
        }
    }
    @mixin responsive-le-l {
        &__form {
            &-field {
                padding: 8px 8px;
            }
        }
    }
    @mixin responsive-le-s {
        &__header {
            flex-direction: column;
            padding: 0;

            &-block {
                padding: 8px 16px;
                width: 100%;

                &:last-child {
                    /*justify-content: space-between;*/
                    background-color: $color-gray-T5;
                    border-top: 1px solid $color-gray-T3;

                    > div:first-child {
                        margin-left: 0;
                    }
                }
            }
            &-auction {
                margin-left: 0;
                align-items: flex-start;
                text-align: left;
            }
            &-status {
                &.is-played {
                    width: auto;
                }
            }
        }

        &__form {
            grid-template-columns: repeat(2, 1fr);

            &-block {
                display: flex;
            }

            &-field {
                width: 50%;
                padding: 8px 16px;
                border-bottom: 1px solid $color-shadow;
            }
        }
    }
    @mixin responsive-le-xs {
        &__header {
            border-bottom: none;

            &-status {
                margin-right: 16px;
                padding: 8px;
                font-size: 12px;
                line-height: 15px;
                &.end-point {
                    display: block;
                }
                &:last-child {
                    margin: 0;
                }
            }
            &-code {
                font-size: 16px;
                line-height: 18px;
            }
            &-name {
                font-size: 18px;
                line-height: 18px;
                margin-right: 16px;
            }
            .timer {
                font-size: 12px;
                line-height: 15px;
                &__status {
                    font-size: 12px;
                    line-height: 15px;
                }
                &__icon {
                    margin-right: 8px;
                }
                &__counter {
                    font-size: 20px;
                    justify-content: center;
                    width: 100%;
                    &-text {
                        font-size: 12px;
                        line-height: 15px;
                        padding-right: 8px;
                    }
                }
                &.has-timer {
                    min-width: 170px;
                    .timer__icon {
                        margin: 0;
                    }
                }
            }
            &-block {
                padding: 8px 16px;
                width: 100%;
            }

            &-slide {
                display: block;
            }
        }

        &.is-open &__header {
            border-bottom: 3px solid $color-gray-T3;
            &-slide:after {
                transform: rotate(180deg);
            }
        }

        &__form {
            grid-template-columns: repeat(1, 1fr);
            max-height: 0;
            overflow: hidden;
            transition: 0.2s ease-in-out;

            &-title {
                justify-content: flex-start;
            }

            &-block {
                display: flex;
            }

            &-block:last-child &-field {
                border-bottom: none;
            }
        }

        &.is-open &__form {
            display: block;
            max-height: 500px;
        }
    }
    @mixin responsive-xxs {
        &__header {
            &-block {
                flex-wrap: wrap;
                padding: 8px 16px 16px;
                &.m--wrap {
                    padding: 16px 16px 8px;
                }
            }
            &-status {
                margin-right: 8px;
                &.docs-status {
                    max-width: 96px;
                }
                &:last-child {
                    margin: 0;
                }
            }
            &-auction {
                display: none;
            }
            .timer {
                margin-right: 0;
                &__icon {
                    min-width: 24px;
                    height: 24px;
                    margin-right: 4px;
                }
            }
        }
    }
}
