.app {
    margin: auto;
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    overflow: hidden auto;

    &__navbar {
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 10;
        max-width: 1920px;
        margin: auto;
        right: 0;
    }

    &__main {
        top: calc(80px + 26px);
    }
    @mixin responsive-le-s {
        &__main {
            top: calc(72px + 26px);
        }
    }
}