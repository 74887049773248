.pagination {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    justify-content: space-between;
    &__info {

    }
    &__block {
        display: flex;
        align-items: center;
        margin-left: auto;
        &-select {
            /*max-width: 120px;*/
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
        }
        &-info {
            white-space: nowrap;
            padding-right: 16px;
        }
    }
    &__buttons {
        display: flex;
        justify-content: space-between;
        align-items: center;
        &-link {
            width: 24px;
            height: 24px;
            cursor: pointer;
            &.is-disabled {
                cursor: unset;
                opacity: 0.5;
            }
            &.is-active {
                border: 1px solid #E31D2C;
                color: #E31D2C;
                box-sizing: border-box;
                border-radius: 2px;
            }
            &.m--prev {
                background: url("./assets/img/next.svg") no-repeat center;
                transform: scaleX(-1);
            }
            &.m--next {
                background: url("./assets/img/next.svg") no-repeat center;
            }
            &.m--number {
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
    .multiselect {
        &__tags {
            height: 40px;
            padding-top: 8px;
            border: 1px solid $color-gray-T3;
            border-radius: 10px;
        }
        &__select:before {
            top: 9px !important;
        }
        &__content-wrapper {
            width: 100%;
            left: 0;
        }
    }
    @mixin responsive-le-l {
    }
    @mixin responsive-le-m {
    }
    @mixin responsive-le-s {
        &__info {
            display: none;
        }
        &__block {
            justify-content: space-between;
            width: 100%;
        }
    }
    @mixin responsive-le-xs {
        &__block {
            justify-content: center;
            &-select {
                display: none;
            }
        }
    }
}