.chat-page {
    display: flex;
    box-shadow: 0 16px 24px rgb(0 0 0 / 6%), 0 2px 6px rgb(0 0 0 / 4%), 0 0 1px rgb(0 0 0 / 4%);
    border-radius: 10px;
    bottom: 24px;
    &__list {
        background-color: $color-gray-T5;
        max-width: 340px;
        width: 100%;

        &-empty {
            display: flex;
            align-items: center;
            flex-direction: column;
            padding: 24px 8px;
            a {
                color: $color-red;
                margin-top: 4px;
                &:hover {
                    text-decoration: underline;
                }
            }
        }

        &-block {
            display: flex;
            justify-content: space-between;
            align-items: center;

            &.new-msg {
                &:after {
                    content: '';
                    background-color: $color-red;
                    border-radius: 50%;
                    width: 16px;
                    height: 16px;
                    display: block;
                }
            }
        }

        &-item {
            background: #FFFFFF;
            border-radius: 5px;
            margin: 8px;
            padding: 8px 24px;
            cursor: pointer;
            &-name {
                padding-right: 24px;
            }
            &-tender {
                font-size: 14px;
                line-height: 17px;
                color: $color-gray-T2;
            }
            &-time {
                font-size: 14px;
                line-height: 16px;
                text-align: right;
                color: $color-gray-T1;
            }
            &-msg {
                border-radius: 50%;
                font-weight: 500;
                font-size: 14px;
                line-height: 21px;
                width: 21px;
                display: block;
                text-align: center;
                color: $color-red;
                background-color: $color-red-light;
            }

            &.is-active {
                background-color: $color-red;
                &:hover {
                    background-color: $color-red;
                }
            }
            &.is-active &-tender, &.is-active &-time {
                color: rgba($color-white, 0.6);
            }
            &.is-active &-name {
                color: $color-white
            }

            &:hover {
                background-color: $color-red-light;
            }
        }
    }

    &__window {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        position: relative;
        max-width: calc(100% - 340px);
        &-empty {
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            position: absolute;
            height: 100%;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }
        &-messages {
            flex-direction: column;
            display: flex;
            height: 100%;
            overflow-y: scroll;
            overflow-x: hidden;
        }
        &-close {
            position: absolute;
            top: 0;
            left: 0;
            display: none;
            width: 56px;
            height: 56px;
            padding: 10px 16px;
            cursor: pointer;
            &:after {
                content: '';
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center;
                width: 24px;
                height: 24px;
                background-image: url("./assets/img/close-messages.svg");
                display: block;
            }

            &:hover {
                background-color: $color-red-light;
            }
        }
        &-title {
            display: none;
            text-align: center;
            padding: 16px 56px 16px 56px;
            margin: 0;
        }
        &-time {
            background: $color-gray-T3;
            padding: 2px 24px;
            margin: 8px auto;
            box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
            border-radius: 6px;
            color: $color-white;
            font-size: 12px;
            line-height: 15px;
            font-weight: 400;

            &:first-child {
                margin-top: auto;
            }
        }
    }

    &__form {
        width: calc(100% - 16px);
        height: 144px;
        margin: 8px;
        display: flex;
        bottom: 0;
        background: #F4F6F8;
        border-radius: 24px;
        overflow: hidden;
        min-height: 144px;
        z-index: 1;
        &-input {
            padding: 16px;
            background: $color-gray-T5;
        }

        &-submit {
            background: $color-red url('./assets/img/send_arrow.svg') no-repeat center;
            border-radius: 50%;
            width: 52px;
            min-width: 52px;
            height: 52px;
            margin-top: auto;
            margin-right: 20px;
            margin-bottom: 20px;
            border: none;
            &.button:hover {
                background: $color-red url('./assets/img/send_arrow.svg') no-repeat center;
            }
        }
    }

    &__message {
        border-radius: 6px;
        margin: 4px auto 4px 16px;
        color: $color-black;
        background: $color-gray-T5;
        max-width: calc(100% - 200px);
        padding: 8px;
        min-width: 200px;
        &.mine {
            background: $color-red;
            color: $color-white;
            margin-left: auto;
            margin-right: 16px;
        }
        &-time {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            text-align: right;
            color: $color-gray-T3;
        }
        &.mine &-time {
            color: rgba($color-white, 0.6);
        }
        &-text {
            word-wrap: break-word;
        }
        &:first-child {
            margin-top: auto;
        }
    }
    @mixin responsive-le-l {
        &__list {
            max-width: 290px;
        }
        &__window {
            max-width: calc(100% - 290px);
        }
    }
    @mixin responsive-le-s {
        bottom: 0;
        left: 0;
        right: 0;
        top: 72px;

        &__list {
            max-width: 100%;
            overflow: hidden;
            transition: transform 0.3s ease-in-out;
            transform: translateX(-100%);
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            height: calc(100vh - 72px);
            &.is-active {
                transform: translateX(0);
            }
        }
        &__window {
            max-width: 100%;
            overflow: hidden;
            transition: transform 0.3s ease-in-out;
            transform: translateX(100%);
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            height: 100%;
            &.is-active {
                transform: translateX(0);
            }
            &-close {
                display: flex;
                align-items: center;
                justify-content: center;
                z-index: 2;
            }
            &-title {
                display: block;
                z-index: 1;
            }
        }
        &__form {
            height: 96px;
            min-height: 96px;
            &-input {
                padding: 12px;
            }
            &-submit {
                width: 34px;
                min-width: 34px;
                height: 34px;
                margin-right: 12px;
                margin-bottom: 12px;
                background-size: 18px;
                &.button:hover {
                    background-size: 18px;
                }
            }
        }
    }
    @mixin responsive-le-xs {
        &__message {
            max-width: calc(100% - 125px);
        }
    }
    @mixin responsive-xxs {
        &__message {
            max-width: calc(100% - 75px);
        }
    }
}