.table {
    background: $color-white;
    box-shadow: 0 16px 24px rgb(0 0 0 / 6%), 0 2px 6px rgb(0 0 0 / 4%), 0 0 1px rgb(0 0 0 / 4%);
    border-radius: 10px;
    overflow: hidden;
    &__header,
    &__row {
        display: grid;
        align-items: center;
        border-bottom: 1px solid $color-gray-T4;
        &-title {
            display: none;
            margin: -16px 16px -16px -16px;
            padding: 16px;
            background: $color-gray-T5;
            width: 50%;
            font-weight: 600;
        }
        &-slide {
            position: absolute;
            display: block;
            top: 50%;
            margin-top: -18px;
            right: 16px;
            width: 36px;
            height: 36px;
            background-color: $color-white;
            border-radius: 50%;
            box-shadow: 0px 2px 4px rgba(38 50 56 / 8%), 0px 4px 6px rgba(38 50 56 / 8%);
            cursor: pointer;

            &:after {
                content: '';
                position: absolute;
                display: block;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                background: url('./assets/img/arrow.svg') no-repeat center;
            }
        }
        &-cell {
            position: relative;
            padding: 16px 16px;
            &.m--center {
                text-align: center;
            }
            &.m--nopadding {
                padding: 0;
                text-align: center;
            }
            &.m--flex {
                display: flex;
                justify-content: space-around;
                align-content: space-between;
                flex-wrap: wrap;
            }
            .m--icon {
                margin: 4px 8px;
            }
            .m--link {
                color: $color-red;
                text-decoration: underline;
                cursor: pointer;
                &:hover {
                    text-decoration: none;
                }
            }
            &.m--header {
                min-height: 58px;
                background: $color-gray-T5;
                border-bottom: transparent;
                padding: 16px;
            }
            &.m--mobile-show {
                display: none;
            }
            &.m--mobile-hide {
                display: block;                
            }
        }
    }
    &__header {
        justify-items: center;
        align-items: center;
        font-weight: 600;
        color: white;
        background: #8D8E92;
        box-shadow: inset 0px -1px 0px #E6E7E9;
        height: 48px;
        &-cell {
            padding: 4px 8px;
            text-align: center;
            hyphens: auto;
            word-wrap: anywhere;
        }
    }
    &__row.is-open &__row-slide:after {
        transform: rotate(180deg);
    }
    &__row.is-open &__row-cell {
        &.m--header {
            background: $color-white;
            border-bottom: 3px solid $color-gray-T4;
        }
    }

    @mixin responsive-le-l {
        &__header,
        &__row {
            font-size: 13px;
            &-cell {
                overflow: hidden;
            }
        }
        &__row {
            &-cell {
                padding: 16px 8px 16px 16px;
            }
        }
    }
    @mixin responsive-le-m {
        &__row {
            &-cell {
                padding: 16px 8px;
            }
        }
    }
    @mixin responsive-le-s {
        box-shadow: none;
        border-radius: 0;
        overflow: inherit;
        &__header {
            display: none;
        }

        &__row {
            &-cell {
                display: none;
                /*align-items: center;*/
                border-bottom: 1px solid $color-gray-T4;
                text-align: center;
                strong {
                    font-weight: 600;
                }
                .m--icon {
                    margin: 16px 16px;
                }
                &.m--flex {
                    display: none;
                    justify-content: flex-end;
                }
                &.m--mobile-show {
                    display: flex;
                }
                &.m--mobile-hide {
                    display: none;                
                }
            }
            &-title {
                display: block;
            }
        }
        &__row.is-open &__row-cell {
            display: flex;
        }
        &__row.is-open &__row-cell.m--mobile-hide {
            display: none;                
        }
    }
}