@font-face {
    font-family: 'Inter';
    src: url('assets/fonts/Inter-Light.otf');
    src: local('Inter'),
        url('assets/fonts/Inter-Light.eot?#iefix') format('embedded-opentype'),
        url('assets/fonts/Inter-Light.woff') format('woff'),
        url('assets/fonts/Inter-Light.woff2') format('woff2'),
        url('assets/fonts/Inter-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Inter';
    src: url('assets/fonts/Inter-Regular.otf');
    src: local('Inter'),
        url('assets/fonts/Inter-Regular.eot?#iefix') format('embedded-opentype'),
        url('assets/fonts/Inter-Regular.woff') format('woff'),
        url('assets/fonts/Inter-Regular.woff2') format('woff2'),
        url('assets/fonts/Inter-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Inter';
    src: url('assets/fonts/Inter-Medium.otf');
    src: local('Inter'),
        url('assets/fonts/Inter-Medium.eot?#iefix') format('embedded-opentype'),
        url('assets/fonts/Inter-Medium.woff') format('woff'),
        url('assets/fonts/Inter-Medium.woff2') format('woff2'),
        url('assets/fonts/Inter-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Inter';
    src: url('assets/fonts/Inter-SemiBold.otf');
    src: local('Inter'),
        url('assets/fonts/Inter-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('assets/fonts/Inter-SemiBold.woff') format('woff'),
        url('assets/fonts/Inter-SemiBold.woff2') format('woff2'),
        url('assets/fonts/Inter-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Inter';
    src: url('assets/fonts/Inter-Bold.otf');
    src: local('Inter'),
        url('assets/fonts/Inter-Bold.eot?#iefix') format('embedded-opentype'),
        url('assets/fonts/Inter-Bold.woff') format('woff'),
        url('assets/fonts/Inter-Bold.woff2') format('woff2'),
        url('assets/fonts/Inter-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}