.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 0;
    top: 0;
    &__block {
        display: flex;
        align-items: center;
        &.m--mobile-hidden {
            display: flex;
        }
        &.m--mobile-show {
            display: none;
        }
    }

    &__text {
        max-width: 180px;
        font-size: 16px;
        line-height: 24px;
        color: $color-gray-T1;
        margin-right: 24px;
        font-weight: 500;
    }

    &__icon {
        width: 40px;
        height: 40px;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;

        margin-right: 24px;
        color: $color-red-light;
        position: relative;

        &.m--notifications {
            background-image: url('./assets/img/notification.svg');
            &.alarm {
                background-image: url('./assets/img/notifications-alarm.svg');
            }
            &:hover {
                background-image: url('./assets/img/notification-active.svg');
            }
        }

        &.m--chat {
            background-image: url('./assets/img/chat.svg');

            &:hover {
                background-image: url('./assets/img/chat-active.svg');
            }
        }

        &-alert {
            position: absolute;
            right: 0;
            top: 0;
            width: 19px;
            height: 19px;
            font-size: 15px;
            line-height: 19px;
            border-radius: 50%;
            background-color: $color-red;
            text-align: center;
        }
    }

    &__username {
        color: $color-black;
        font-weight: 500;
        font-size: 16px;
        line-height: 115%;
        margin-right: 24px;
    }
    &__burger {
        position: absolute;
        display: none;
        background-image: url("./assets/img/burger.svg");
        background-size: contain;
        background-repeat: no-repeat;
        top: 28px;
        right: -16px;
        height: 27px;
        width: 40px;
        cursor: pointer;
        &.alert {
            &:after {
                content: '';
                position: absolute;
                display: block;
                width: 16px;
                height: 16px;
                background-color: $color-red;
                border-radius: 50%;
                right: -6px;
                top: -6px;
            }
        }
    }
    @mixin responsive-le-m {
        &__block {
            &.m--mobile-hidden {
                display: none;
            }
            &.m--mobile-show {
                display: flex;
            }
        }
        &__burger {
            display: block;
            right: 0;
        }
    }
    @mixin responsive-le-s {
        padding: 12px 0;
        &__burger {
            top: 23px;
        }
    }
    @mixin responsive-le-xs {
        &__text {
            display: none;
        }
    }
}