.notifications {
    display: flex;
    flex-direction: column;

    &__item {
        margin-bottom: 24px;
        background: $color-white;
        padding: 16px 24px;
        border: 1px solid $color-gray-T4;
        box-sizing: border-box;
        box-shadow: 0 3px 6px rgb(75 81 91 / 15%);
        border-radius: 10px;
        min-height: 50px;
        color: $color-gray-T2;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 22px;

        &-title {
            font-weight: bold;
            color: $color-black;
            margin-bottom: 16px;
        }
        &-link,
        &-link a {
            color: $color-red;
            text-decoration: underline;
            &:hover {
                text-decoration: none;
            }
        }
    }
    @mixin responsive-le-s {
        &__item {
            margin-bottom: 16px;
            font-size: 16px;
            line-height: 20px;
            padding: 16px;
        }
    }
    @mixin responsive-xxs {
        &__item {
            padding: 12px;
            font-size: 14px;
            line-height: 18px;
        }
    }
}