.region {
    border-bottom: 0.5px solid #ECECEC;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    padding: 8px 0;
    &-label {
        min-width: 100px;
    }
    &:first-child {
        padding-top: 0;
    }
}

.city {
    &-label {
        margin-left: 24px;
        color: #C7C7C7;
        display: flex;
    }
    &-name {
        &:hover {
            color: #E31D2C;
            text-decoration: underline;
        }
    }
    &-delete {
        background-image: svg-load('./assets/img/close.svg', fill=$color-gray-T3);
        background-size: 6px 20px;
        background-position: top right;
        background-repeat: no-repeat;
        display: inline-block;
        min-width: 20px;
        min-height: 20px;
        position: relative;
        top: -4px;
        cursor: pointer;

        &:hover {
            background-image: svg-load('./assets/img/close.svg', fill=$color-red);
        }
    }
}
