.select-modal {
    &__row {
        position: relative;
        padding-left: 40px;
        align-items: center;
        margin-bottom: 16px;
        cursor: pointer;

        &:before {
            content: '';
            position: absolute;
            left: 0;
            min-width: 24px;
            min-height: 24px;
            top: calc(50% - 12px);
            border-radius: 50%;
            border: 1px solid $color-red;
        }

        &:after {
            content: '';
            position: absolute;
            left: 6px;
            min-width: 12px;
            min-height: 12px;
            top: calc(50% - 6px);
            border-radius: 50%;
        }

        &:hover {
            &:after {
                background-color: $color-red-light;
            }
        }
        &.is-active {
            &:after {
                background-color: $color-red;
            }
        }
    }
    &__field {
        position: relative;
        margin-bottom: 0;
    }
}